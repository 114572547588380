<template>
    <div class="bg-mafia-dark flex-column position-relative">
        <div class="flex-1 flex-column justify-center">
            <div>
                <img :src="require('@/assets/image/web/intro/ico_char01.svg')" alt="마피아" id="black_man" />
            </div>
            <div class="text_guide_desc pt-20">
                <p class="color-bbb">서비스 이용동의를 위해서는<br />약관동의가 필요합니다.</p>
            </div>

            <div class="intro_bottom text-left pa-10-20 mt-30">
                <div
                    class="dark_checks"
                    @click="doAll"
                >
                    <input
                        v-model="is_all"
                        type="checkbox" id="all"
                        readonly
                        class="mr-10"
                    >
                    <label >모두 확인, 동의 합니다.</label>
                </div>

                <hr class="mt-20 under-line" style="border-color: white; opacity: 0.2" />

                <div
                    class="flex-row justify-space-between mt-20 cursor-pointer dark_checks"
                    @click="to('terms')"
                >
                    <div class="flex-1">
                        <input
                            v-model="is_terms"
                            type="checkbox" id="agree1"
                            class="mr-10"
                            readonly
                        >
                        <label class="cursor-pointer">(필수) 서비스 이용약관</label>
                    </div>
                    <button type="button" class="" @click="to('terms')"><i class="icon-arrow-right"></i></button>
                </div>

                <div class="flex-row justify-space-between mt-20 dark_checks" @click="to('personal')">
                    <div class="flex-1">
                        <input
                            v-model="is_personal"
                            type="checkbox"
                            class="mr-10"
                        >
                        <label class="cursor-pointer">(필수) 개인정보 수집 및 이용동의</label>
                    </div>
                    <button type="button" class="" @click="to('personal')"><i class="icon-arrow-right"></i></button>
                </div>
            </div>
        </div>

<!--		<div class="intro_btn_wrap pa-30">
				&lt;!&ndash; TODO : 동의 체크 완료후 class : disable 없애기 &ndash;&gt;
        <button
            class="btn btn-blue radius-20"
            :class="{ disable: !is_agree}"

            @click="toJoin"
        >다음</button>

        <p class="copyright mt-30">Copyright © MAFI Inc. </p>
        </div>-->

        <div class="intro_btn_wrap mb-20">
            <div class="btn btn_area">
                <button class="btn_l btn_fill_blue" :class="{ disable: !is_agree }" @click="toJoin">다음</button>
            </div>
            <!-- <p class="copyright mt-30 ">Copyright © MAFI Inc. </p> -->
        </div>

        <div :class="{'popup' : is_0041}">
                <mafia0041
                    v-if="is_0041"
                    :type="type"
                    style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: white;"

                    @cancel="cancel"
                    @click="setAuth"
                ></mafia0041>
        </div>
    </div>
</template>

<script>
import mafia0041 from '@/view/Auth/mafia004-1'
export default {
    name: 'mafia004'
    , components: { mafia0041 }
    ,data: function(){
        return {
            program: {
                name: '가입 계정 확인'
                , not_header: true
                , not_footer: true
                , type: 'auth'
            }
            , is_all: false
            , is_terms: false
            , is_personal: false
            , is_0041: false
            , type: 'terms'
            , terms: {}
            , personal: {}
        }
    }
    , computed: {
        is_agree: function(){
            let is_agree = false

            if(this.is_terms && this.is_personal){
              is_agree = true
                  }
            return is_agree
        }
        , terms_version: function(){
            let t = 'v1'
            if(process.env.VUE_APP_VERSION_TERMS != t){
                t = process.env.VUE_APP_VERSION_TERMS
            }

            return t
        }
        , personal_version: function(){
            let t = 'v1'
            if(process.env.VUE_APP_VERSION_PERSONAL != t){
                t = process.env.VUE_APP_VERSION_VUE_APP_VERSION_PERSONAL
            }

          return t
        }
    }
    ,methods: {
        getData: async function(){
            try {

                const result = await this.$Request({
                    method: 'POST'
                    , url: this.$api_url.api_path.get_agreement
                    , data: {
                    }
                    , type: true
                })
                this.$log.console('result.success', result.success)
                if (result.success) {
                    this.$log.console('success')
                    this.terms = result.data.stip_list[1]
                    this.personal = result.data.stip_list[0]
                } else {
                    throw result.message
                }
            } catch (e) {
                this.$log.console(e)
            }
                }
        , to: function(type){
            this.$log.console('check박스 클릭')
                if(type == 'terms'){
                    if(this.is_terms){
                        this.is_terms = false
                        return
                            }
                    }else if(type == 'personal'){
                    if(this.is_personal){
                      this.is_personal = false
                      return
                    }
                }
                this.is_0041 = true
                this.$emit('onPopup')
                this.type = type
            /*
        if(type == 'terms'){
            if(this.terms.stip_offer_code == 'CO00200002'){
              window.open(this.terms.stip_file_url, 'terms')
            }else{
              this.is_0041 = true
              this.type = type
            }
        }else{
            if(this.personal.stip_offer_code == 'CO00200002'){
                window.open(this.personal.stip_file_url, 'personal')
            }else{
                this.is_0041 = true
                this.type = type
            }
        }
			 */
            }
        , doAll: function(){
            if(!this.is_terms){
                this.to('terms')
            }else if(!this.is_personal){
                this.to('personal')
            }else{
                this.is_terms = false
                this.is_personal = false
            }
        }
        , toJoin: function(){
            if(!this.is_terms || !this.is_personal){
                this.$bus.$emit('notify', { type: 'error', message: '서비스 이용을 위해서는 약관동의가 필요합니다'})
            }else{

                let join_info = localStorage.getItem('join_info')
                if(!join_info){
                    join_info = {
                    }
                }else{
                    join_info = JSON.parse(join_info)
                }

                if(!join_info.terms_version){
                    this.to('terms')
                }else if(!join_info.personal_version){
                    this.to('personal')
                }else{
                    this.$emit('to', { name: 'join'})
                }
            }
        }
        , setAuth: function(type){
            if(type == 'terms'){
                this.is_terms = true
            }
            if(type == 'personal'){
                this.is_personal = true
            }
            this.is_0041 = false
            this.$emit('offPopup')
        }

        , cancel() {
            this.is_0041 = false
            this.$emit('offPopup')
        }
    }
	,created() {
      this.$emit('onLoad', this.program)
      localStorage.setItem('join_info', JSON.stringify({}))
      this.getData()
	}
	,watch: {
        is_terms: {
            handler: function(call){
                if(!call){
                    this.is_all = false
                }else{
                    if(this.is_personal){
                        this.is_all = true
                    }
                }
            }
        }
        , is_personal: {
            handler: function(call){
                if(!call){
                    this.is_all = false
                }else{
                    if(this.is_terms){
                        this.is_all = true
                    }
                }
            }
        }
    }
}
</script>
<style>
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    width: 500px;
    height: 700px;
    box-shadow: 7px 7px 5px rgba(0,0,0,0.2);
    z-index: 100010;
}


.dark_checks label {
    color : var(--white);
}

p {
    color: white;
}

.btn { display: inline-block; width: 100%; text-align: center; padding: 10px; font-size: 16px; color: white;}
.btn_l {
    display: inline-block;
    height: 3.5rem;
    line-height: 1rem;
    min-width: 9rem;
    padding: 0 3rem;
    border-radius: 5rem;
    border-style: solid;
    border-width: 0.1rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.1rem;
    text-align: center;
    box-sizing: border-box;
}

.intro_btn_wrap { margin: 0 2rem; left: 0; right: 0; bottom: 3rem; }
.btn_area { display: flex; margin: 0 -0.5rem; }
.btn_area button, .btn_area a { margin: 0 0.5rem; padding: 0 1rem; flex: 1; }


#black_man {
    width: 150px;
    height: 120px;
    object-fit: cover;
}

</style>