<template>
	<div class="flex-column popup_01">
		<template
			v-if="type == 'terms'"
		>
			<div class="top-sub flex-row justify-space-between popup_title">
				<h4 class="pl-30">이용약관</h4>
				<div class="pr-30">
					<button class="arrow" @click="$emit('cancel')">
						<i class="icon-close color-white "></i>
					</button>
				</div>
			</div>
			<div class="full-height flex-column popup_bottom mt-30 ml-30 mr-30">
				<div class="full-height">
					<iframe
						v-if="terms.stip_file_url"
						:src="terms.stip_file_url"
						style="width: 100%; height: 100%; border: none"
					></iframe>
					<div
						v-else
						class="row"
					>
						<div
							class="terms_wrap" v-html="terms.strip_contents"
						></div>
					</div>
				</div>

				<div
					class="agree-btn-wrap mt-25 mb-25"
				>
					<button
						class="btn"
						@click="setAgree"
					>
						동의합니다
					</button>
				</div>
			</div>
		</template>

<!--		<template
			v-else-if="type == 'personal'"
		>
			<div class="top-sub flex-row justify-space-between popup_title">
				<h4 class="pl-30">개인정보 이용방침</h4>
				<div class="pr-30">
					<button class="arrow" @click="$emit('cancel')">
						<i class="icon icon-close color-white "></i>
					</button>
				</div>
			</div>
			<div class="full-height flex-column ">
				<div class="full-height">
					<iframe
						v-if="personal.stip_file_url"
						:src="personal.stip_file_url"
						style="width: 100%; height: 90vh; border: none"
					></iframe>
					<div
						v-else
						class="row"
					>
						<div
							class="terms_wrap" v-html="personal.strip_contents"
						></div>
					</div>
				</div>

				<div
					class=""
				>
					<button
						class="btn btn-blue"
						@click="setPersonal"
					>
						동의합니다
					</button>
				</div>
			</div>
		</template>-->

    <template
        v-else-if="type == 'personal'"
    >
      <div class="top-sub flex-row justify-space-between popup_title">
        <h4 class="pl-30">이용약관</h4>
        <div class="pr-30">
          <button class="arrow" @click="$emit('cancel')">
            <i class="icon-close color-white "></i>
          </button>
        </div>
      </div>
      <div class="full-height flex-column popup_bottom mt-30 ml-30 mr-30">
        <div class="full-height">
          <iframe
              v-if="personal.stip_file_url"
              :src="personal.stip_file_url"
              style="width: 100%; height: 100%; border: none"
          ></iframe>
          <div
              v-else
              class="row"
          >
            <div
                class="terms_wrap" v-html="personal.strip_contents"
            ></div>
          </div>
        </div>

        <div
            class="agree-btn-wrap mt-25 mb-25"
        >
          <button
              class="btn btn-blue"
              @click="setPersonal"
          >
            동의합니다
          </button>
        </div>
      </div>
    </template>
	</div>
</template>

<script>
	export default {
		name: 'mafia0041'
		, props: ['type']
		, data: function(){
			return {
				program: {
					name: 'agree'
					, type: 'agree'
					, not_footer: true
				}
				, terms: {
					stip_number: ''
				}
				, personal: {
					stip_number: ''
				}
			}
		}
		, computed:{

		}
		, methods: {
			getData: async function(){
				try {

					const result = await this.$Request({
						method: 'POST'
						, url: this.$api_url.api_path.get_agreement
						, data: {

						}
						, type: true
					})
					this.$log.console('result.success', result.success)
					if (result.success) {
						this.$log.console('success')
						for(let i = 0; i < result.data.stip_list.length; i++){
							let t = result.data.stip_list[i]
							if(t.stip_code == 'CO00100002') {
								this.terms = t
								continue
							}
							if(t.stip_code == 'CO00100001') {
								this.personal = t
								continue
							}
						}
						// this.getFiles()
					} else {
						throw result.message
					}
				} catch (e) {
					this.$log.console(e)
				}
			}
			, setAgree: function(){
				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
						terms_version: ''
					}
				}else{
					join_info = JSON.parse(join_info)
				}
				this.$log.console('this.terms', this.terms)
				join_info.terms_version = this.terms.stip_number
				localStorage.setItem('join_info', JSON.stringify(join_info))
				this.$emit('click', 'terms')
			}
			, setPersonal: function(){
				let join_info = localStorage.getItem('join_info')
				if(!join_info){
					join_info = {
						personal_version: ''
					}
				}else{
					join_info = JSON.parse(join_info)
				}
				join_info.personal_version = this.personal.stip_number
				localStorage.setItem('join_info', JSON.stringify(join_info))
				this.$emit('click', 'personal')
			}
			, getFiles: function(){
				if(this.type == 'terms'){
					if(this.terms.stip_offer_code == 'CO00200002'){
						window.open(this.terms.stip_file_url, 'terms')
					}
				}else{
					if(this.personal.stip_offer_code == 'CO00200002'){
						window.open(this.personal.stip_file_url, 'terms')
					}
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>
<style>


	.agree-btn-wrap { margin-top: auto}
	.agree-btn-wrap .btn { width: 30%; text-align: center; background-color: #171c61; padding: 10px; font-size: 14px; color: white; border-radius: 20px}
	.section_wrap::after { padding-bottom: 0}

  .popup_01 {
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 7px 7px 5px rgba(0,0,0,0.2);
    z-index: 3;
  }

  .popup_title {
    border-radius: 20px 20px 0 0
  }

  .popup_bottom {
    border-radius: 0 0 20px 20px;
  }

  h4 {
    color : white
  }
</style>